import * as React from "react"
import { Helmet } from "react-helmet"

import main from "./main.png"
import whatsapp from "./whatsapp-icon.png"

const IndexPage = () => {
  return (
    <main class="prose flex flex-col items-center content-center w-full h-screen py-4 border-r-8 border-blue-400 text-center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tatyana Emérito - Fonoaudióloga</title>
        <link rel="icon" href="/static/favicon.ico" />
      </Helmet>
      <div>
        <a href="http://lattes.cnpq.br/8496888617319765">
          <img src={main} alt="Tatyana Emérito" />
        </a>
        <h1 className="text-pink-400 text-4xl uppercase my-4">Tatyana Emérito</h1>
        <h2 className="text-blue-400 text-2xl uppercase mt-4">Fonoaudióloga</h2>
        <h6 className="text-blue-400 text-xs my-2">CRFa 8 10733-6</h6>
        <a className="no-underline hover:no-underline text-pink-400 my-1" href="https://wa.me/5586988318499" target="_blank" rel="noreferrer">
          <img src={whatsapp} alt="86 8831 8499" className="inline-block h-8 w-8" />
          {' '}
        (86) 98831 8499
      </a>
      </div>
    </main>
  )
}

export default IndexPage
